import { store } from '@/store/store';
import { anotaciones_dieta } from '@/shared/dtos/anotaciones_dieta';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'anotaciones_dietaModule',
    store,
    dynamic: true
})
class anotaciones_dietaModule extends VuexModule {
    public anotaciones_dietas: anotaciones_dieta[] = [];
    public anotaciones_dieta: anotaciones_dieta = new anotaciones_dieta();

    @Action({ commit: 'onGetanotaciones_dietas' })
    public async getanotaciones_dietas() {
        return await ssmHttpService.get(API.anotaciones_dieta);
    }

    @Action({ commit: 'onGetanotaciones_dieta' })
    public async getanotaciones_dieta(id: any) {
        return await ssmHttpService.get(API.anotaciones_dieta + '/' + id);
    }

    @Action
    public async guardaranotaciones_dieta(anotaciones_dieta: anotaciones_dieta) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        return await ssmHttpService.post(API.anotaciones_dieta, anotaciones_dieta.toJson());
    }

    @Action
    public async modificaranotaciones_dieta(anotaciones_dieta: anotaciones_dieta) {
        return await ssmHttpService.put(API.anotaciones_dieta + '/' + anotaciones_dieta.id, anotaciones_dieta);
    }

    @Action
    public async eliminaranotaciones_dieta(anotaciones_dieta: anotaciones_dieta) {
        return await ssmHttpService.delete(API.anotaciones_dieta + '/' + anotaciones_dieta.id, null, false);
    }

    @Mutation
    public onGetanotaciones_dietas(res: anotaciones_dieta[]) {

        this.anotaciones_dietas = res ? res.map((x) => new anotaciones_dieta(x)) : []
    }

    @Mutation
    public onGetanotaciones_dieta(res: anotaciones_dieta) {
        this.anotaciones_dieta = new anotaciones_dieta(res);
    }

}
export default getModule(anotaciones_dietaModule);