








































import { ssmMessageService } from "@/shared/services/message-service";
import anotaciones_dietaModule from "@/store/modules/anotaciones_dieta-module";
import { UtilsString } from "@/utils/utils-string";
import { Component, Vue, PropSync } from "vue-property-decorator";

@Component({
  components: {
    HTMLEditor: () => import("@/components/html-editor/html-editor.vue"),
  },
  $_veeValidate: { validator: "new" },
})
export default class dialog_anotaciones_dieta extends Vue {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: Boolean;
  @PropSync("id_paciente", { type: Number }) syncedId_Paciente!: number;
  @PropSync("id_dieta", { type: Number }) syncedId_Dieta!: number;
  public anotaciones_dieta: string = "";

  async created() {
    await anotaciones_dietaModule.getanotaciones_dieta(this.syncedId_Dieta);
    console.log(anotaciones_dietaModule.anotaciones_dieta);
    anotaciones_dietaModule.anotaciones_dieta === undefined
      ? (this.anotaciones_dieta = "")
      : (this.anotaciones_dieta = UtilsString.ValueOf(
          anotaciones_dietaModule.anotaciones_dieta.anotaciones_html
        ));
  }

  public close() {
    this.$emit("close");
    this.syncedDialog = false;
  }
  public async save() {
    anotaciones_dietaModule.anotaciones_dieta.anotaciones_html =
      this.anotaciones_dieta;
    anotaciones_dietaModule.anotaciones_dieta.id_dieta = this.syncedId_Dieta;
    this.guaradar_anotaciones().then(() => {
      ssmMessageService.toastsuccesful("Anotaciones guardadas correctamente");

      this.close();
    });
  }

  guaradar_anotaciones() {
    if (anotaciones_dietaModule.anotaciones_dieta.id === null) {
      return anotaciones_dietaModule.guardaranotaciones_dieta(
        anotaciones_dietaModule.anotaciones_dieta
      );
    } else {
      return anotaciones_dietaModule.modificaranotaciones_dieta(
        anotaciones_dietaModule.anotaciones_dieta
      );
    }
  }
}
